<div class="grid pad:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-x-8 gap-y-10">
  <div class="car-data-box bg-white shadow-md p-4 flex flex-wrap rounded-md items-start relative" *ngFor="let car of cars ">
    <div
      class="close-deal-badge bg-[url('assets/img/yellow-ribbon.png')] bg-no-repeat w-48 h-14 bg-contain absolute  top-10 -left-[10px] z-10 text-base text-black font-medium px-6 py-1.5 leading-normal	" *ngIf="currentRoute == 'offers' && car?.bidId == user?.id && car?.bidAmount < car?.maxBidAmount">
      Losing your Deal
    </div>
    <div class="close-deal-badge bg-[url('assets/img/red-ribbon.png')] bg-no-repeat w-48 h-14 bg-contain absolute  top-10 -left-[7px] z-10 text-lg text-white font-medium px-6 py-1.5"
    *ngIf="currentRoute == 'offers' && car?.bidId == user?.id && car?.bidAmount == car?.maxBidAmount && car?.timeUnit  == 'minutes'">
      About to close
  </div>
    <div
      class="xl:w-4/12 w-full max-h-[210px] data-img text-center rounded-md overflow-hidden relative after after:absolute after:w-full after:h-full after:top-0 after:left-0 after:opacity-20 after:bg-black ">

      <img *ngIf="car.imageUrl" src="{{ car?.imageUrl }}" alt="Car Name" class="inline-block h-[210px]">
      <img *ngIf="!car.imageUrl" src="../../../assets/img/car_palceholder.png" alt="Car Name"
        class="inline-block w-full">

    </div>
    <div class="relative  xl:w-8/12 w-full xl:px-4 px-0 py-4 xl:py-0">
      <div class="car-data-head md:flex justify-end mb-4">
        <div class="left-block">
          <div class="model-car-year text-xl font-medium mb-1 text-black">{{car?.modelYear}} {{car?.carName}}
            {{car?.modelName}} </div>
          <div class="model-car-location grey_text_500 text-base font-medium"><span class="w-4 h-4 inline-block"><svg
                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"
                fill="#00459f">
                <g>
                  <path d="M49.3,96.2c-1.5-1.3-3-2.4-4.4-3.8c-7.9-7.8-15-16.3-20.7-26c-3.6-6.2-6.5-12.7-8-19.8c-3.6-16.7,4.6-33.2,20.2-40.1
                           C50.5,0.4,67.5,4.7,77.2,17c5.5,7,8,15.1,7.5,24c-0.4,6.5-2.4,12.6-5.1,18.5C75.1,68.9,69.1,77.2,62.2,85c-3,3.3-6.2,6.5-9.3,9.7
                           c-0.6,0.6-1.4,1.1-2.1,1.6C50.3,96.2,49.8,96.2,49.3,96.2z M69.2,38.4c0-10.6-8.6-19.2-19.2-19.2c-10.6,0-19.3,8.7-19.2,19.3
                           c0.1,10.6,8.7,19.2,19.3,19.2C60.7,57.6,69.3,49,69.2,38.4z" />
                </g>
              </svg>
            </span> {{car?.city}} , {{car?.state}}</div>
        </div>
        <div class="session-time ml-auto text-base font-semibold"
          [ngClass]="{'text-red': car?.timeUnit == 'minutes', 'text-green': car?.timeUnit == 'hours'}">
          <span *ngIf="!car?.bidWinnerId && car?.allImageTypesExist">

            {{car?.remainingTime}} {{car?.timeUnit}} left
          </span>
        </div>

      </div>

      <div class="model-car-info font-medium text-base space-y-1.5 p-0">
        <div class="flex flex-wrap gap-y-1">

          <div class="text-base grey_text_500 lg:w-6/12 w-full" *ngIf=" (car.rejectionRequest==1 || car.rejectionStatus==1) && isDealer">
            <span class="text-black mr-2">Status:</span>
              <span class="text-red-600 text-lg font-semibold px-2">Rejection {{car.rejectionRequest==1 ? 'Pending' :'Disapproved'}} </span>
            </div>
          <!-- Dealership Name -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full"
            *ngIf="((currentRoute != 'car-list-new' && currentRoute != 'dealer-preferred-list') || (!isDealer && car.purchased)) && car?.dealershipName">
            <span class="text-black mr-2">Dealership Name: </span>
            <span>{{ car?.dealershipName }}</span>
          </div>
          <!-- Employee Name (only if isDealer and car.maxBidAmount exist) -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full"
            *ngIf="((currentRoute != 'car-list-new' && currentRoute != 'dealer-preferred-list') || (!isDealer && car.purchased)) && car?.employeeName">
            <span class="text-black mr-2">Employee Name: </span>
            <span class="text-green font-semibold">{{ car?.employeeName }}</span>
          </div>
          <!-- Mileage -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full">
            <span class="text-black mr-2">Mileage:</span>
            <span>{{ car?.mileage }} Miles</span>
          </div>
          <!-- Highest Offer (only if isDealer and car.maxBidAmount exist) -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full" *ngIf="isDealer && car?.maxBidAmount">
            <span class="text-black mr-2">Highest Offer:</span>
            <span class="text-green font-semibold">${{ car?.maxBidAmount }}</span>
          </div>
          <div class="text-base grey_text_500 lg:w-6/12 w-full"
            *ngIf="isDealer && car?.maxBidAmount && currentRoute!='car-list-new' && currentRoute!= 'dealer-preferred-list'">
            <span class="text-black mr-2">My Offer :</span>
            <span class="text-green font-semibold">${{ car?.bidAmount }}</span>
          </div>
          <!-- Status (only if not isDealer) -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full" *ngIf="!isDealer">
            <span class="text-black mr-2">Status:</span>
            <span *ngIf="!car?.allImageTypesExist">
              <span class="text-red-600 text-lg font-semibold px-2">Vehicle not listed </span>
            </span>
            <!-- <span *ngIf="!car?.allImageTypesExist">
              <span class="text-green text-lg font-bold px-2">NOT LISTED </span>
            </span> -->
            <span *ngIf="car?.allImageTypesExist">
              <span class="text-green text-lg font-bold px-2" *ngIf="car.purchased == 1; else checkRejected" >PURCHASED </span>
              <ng-template #checkRejected>
                <span class="text-green text-lg font-bold px-2" *ngIf="car.rejected == 1 ; else checkBidWinner">REJECTED </span>
              </ng-template> 
              <ng-template #checkBidWinner>
                <span class="text-green text-lg font-bold px-2" *ngIf="car.bidWinnerId > 0; else unSold">SOLD </span>
              </ng-template>
              <ng-template #unSold>
                <span class="text-green text-lg font-bold px-2" *ngIf="car.isRelist==1 && !car?.bidWinnerId; else relisted">UNSOLD </span>
              </ng-template>
              <ng-template #relisted>
                <ng-container *ngIf="(car.isRelist==0 && car.relistStatus==1); else inProgress ">RELISTED</ng-container>
              </ng-template>
              <ng-template #inProgress>
                <span class="text-green text-lg font-bold px-2">In Progress</span>
              </ng-template>
            </span>
           
          </div>

          <div class="text-base grey_text_500 lg:w-6/12 w-full"
            *ngIf="car?.purchaseAmount && currentRoute == 'car-purchased'">
            <span class="text-black mr-2">Purchased Amount:</span>
            <span>${{ car?.purchaseAmount }}</span>
          </div>
          <!-- Color -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full">
            <span class="text-black mr-2">Color:</span>
            <span>{{ car?.color }}</span>
          </div>
          <!-- Customer's Expected Offer (only if car.sellingPrice > 0) -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full" *ngIf="car?.sellingPrice > 0">
            <span class="text-black mr-2">Customer's Expected Offer:</span>
            <span class="text-green font-semibold">${{ car?.sellingPrice }}</span>
          </div>
          <!-- Car Listed Successfully (only if not isDealer) -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full" *ngIf="!isDealer">
            <div class="text-base text-black font-medium flex items-center">
              Vehicle Listed Successfully:
              <span class="checked inline-block ml-1">
                <i class="fa-solid fa-square-check text-xl text-green" *ngIf="!car.isRelist"></i>
                <i class="fa fa-square-xmark mr-1 text-xl text-red" *ngIf="car.isRelist"></i>
              </span>
            </div>
          </div>
          <!-- Car Images Updated (only if not isDealer) -->
          <div class="text-base grey_text_500 lg:w-6/12 w-full" *ngIf="!isDealer">
            <span class="text-black mr-2">Vehicle Images Updated:</span>
            <span class="checked inline-block ml-1" *ngIf="car?.allImageTypesExist">
              <i class="fa-solid fa-square-check text-xl text-green"></i>
            </span>
            <span class="checked inline-flex items-center ml-1 text-sm text-red" *ngIf="!car?.allImageTypesExist">
              <i class="fa fa-square-xmark mr-1 text-xl"></i>
            </span>
            <!-- <div *ngIf="!car?.allImageTypesExist" class="text-red-600">To successfully list your vehicle and start receiving offers, kindly upload all required images by clicking on the "Edit" button
              </div> -->
          </div>
          <div *ngIf="!car?.allImageTypesExist" class="text-red-600">To successfully list your vehicle and start
            receiving offers, kindly upload all required images by clicking on the "Edit" button
          </div>
        </div>
      </div>

      <div *ngIf="!isDealer && !car.allImageTypesExist">
        <div class="action-button space-y-4 mt-6">
          <div class="btn-box flex flex-wrap gap-4">
            <a class="bg-yellow rounded-full border border-transparent text-base text-white block py-2 lg:w-6/12 lg:flex-none lg:whitespace-normal flex-1 whitespace-nowrap  text-center cursor-pointer px-4 ml-auto"
              routerLink="/seller/edit-car/{{car?.id}}" *ngIf="!car.bidWinnerId"><i class="fa fa-pencil mr-4"></i>
              Edit </a>
          </div>
        </div>
      </div>

      <!-- seller button -->
      <div *ngIf="!isDealer && !car.purchased && car.allImageTypesExist && car?.rejected == 0">
        <div class="action-button space-y-4 mt-6">
          <div class="btn-box flex flex-wrap gap-4">
            <a class="bg-yellow rounded-full border border-transparent text-base text-white block py-2  flex-1 whitespace-nowrap  text-center cursor-pointer px-4"
              routerLink="/seller/edit-car/{{car?.id}}" *ngIf="!car.bidWinnerId"><i class="fa fa-pencil mr-4"></i>
              Edit </a>
            <a routerLink="/seller/offer/{{car?.id}}"
              class="bg_primary rounded-full border border-transparent text-base text-white block  py-2 text-center cursor-pointer flex-1 whitespace-nowrap px-4"
              *ngIf="car?.allImageTypesExist">View
              Vehicle Offers</a>
            <a *ngIf="car?.bidWinnerId"
              class="bg-green rounded-full border border-transparent text-base text-white block py-2  text-center cursor-pointer flex-1 whitespace-nowrap px-4"
              (click)="showCustomer(car?.dealerId,car?.parentUserId)">View
              Dealer Info</a>
            <a *ngIf="car?.remainingTime == 0 && !car?.bidWinnerId && car?.allImageTypesExist"
              class="bg-green rounded-full border border-transparent text-base text-white block  py-2 text-center cursor-pointer flex-1 whitespace-nowrap px-4"
              (click)="reslistCar(car?.id,car?.isRelist)">Relist
              your vehicle</a>
            <a *ngIf="car?.bidWinnerId && car?.scheduleDate && !car.rejected"
              class="bg_primary rounded-full  border border-transparent text-base text-white block py-2 text-center flex-1 whitespace-nowrap cursor-pointer px-4 "
              (click)="showSummary(car)">
              {{car?.pickup>0 ? 'Pick-Up' : 'Drop-Off'}} Info</a>
              <a *ngIf="car.rejected" routerLink="/seller/rating/{{car?.dealerId}}/{{car?.vinNumber}}"
              class="bg-yellow rounded-full border border-transparent text-base text-black block  py-2 flex-1 whitespace-nowrap px-4 text-center cursor-pointer">Rate
              the Dealer</a>
          </div>
        </div>

      </div>
      <div *ngIf="!isDealer  && car.purchased">
        <div class="action-button space-y-4 mt-6">
          <div class="btn-box flex flex-wrap sm:flex-auto gap-4">
            <a *ngIf="car.purchased"
              class="bg-green rounded-full border border-transparent text-base text-white block py-2  text-center cursor-pointer flex-1 whitespace-nowrap px-4"
              (click)="showCustomer(car?.dealerId,car?.parentUserId)">View
              Dealer Info</a>
            <a routerLink="/dealer/view-car-info/{{car?.id}}"
              class="bg-green rounded-full border border-transparent text-base text-white block  py-2  text-center cursor-pointer flex-1 whitespace-nowrap px-4">View
              Vehicle Info</a>
          </div>
          <div class="btn-box flex flex-wrap gap-4">
            <a *ngIf="car.rejected || car.purchased"
              class="bg_primary rounded-full border border-transparent text-base text-white block py-2  text-center cursor-pointer flex-1 whitespace-nowrap px-4"
              (click)="showSummary(car)">{{car?.pickup ? 'Pick-Up' : 'Drop-Off'}} Info</a>
            <a *ngIf="car.rejected || car.purchased" routerLink="/seller/rating/{{car?.dealerId}}/{{car?.vinNumber}}"
              class="bg-yellow rounded-full border border-transparent text-base text-black block  py-2  text-center cursor-pointer flex-1 whitespace-nowrap px-4">Rate
              the Dealer</a>
          </div>
        </div>
      </div>
      <div *ngIf="isDealer && currentRoute == 'car-purchased'"
        class="model-action flex justify-center flex-wrap gap-4 font-medium mt-4 text-center">
        <a routerLink="/dealer/view-car-info/{{car?.id}}"
          class="bg-green rounded-full py-2 text-white block flex-1 whitespace-nowrap px-4">View Vehicle Info</a>
      </div>

      <div *ngIf="isDealer && !schedulePage && currentRoute != 'car-purchased'"
        class="model-action flex justify-center flex-wrap gap-4 font-medium mt-4 text-center">
        <a routerLink="/dealer/car-history/{{car?.id}}"
          class="bg-[#E98F03] rounded-full py-2 text-white block flex-1 whitespace-nowrap px-4"
          *ngIf="currentRoute == 'offers' || currentRoute == 'won-offers'">History
        </a>
        <a routerLink="/dealer/view-car-info/{{car?.id}}"
          class="bg-green rounded-full py-2 text-white block flex-1 whitespace-nowrap px-4"
          *ngIf="currentRoute != 'car-rejected'">View Vehicle
          Info</a>
        <a class="bg_primary rounded-full py-2 text-white block cursor-pointer flex-1 whitespace-nowrap px-4"
          (click)="showBidModel(car)" *ngIf="currentRoute != 'won-offers' && !car?.bidCompleted">{{car?.dealerId ==
          user?.id ? 'Update Offer' : 'Offer Now'}} </a>
          <a class="bg-green rounded-full py-2 text-white block cursor-pointer flex-1 whitespace-nowrap px-4"
          (click)="showCustomer(car?.userId,0)" *ngIf="currentRoute== 'won-offers'">View Customer Info</a>
        <a *ngIf="car?.bidWinnerId && currentRoute != 'car-rejected'"
          class="bg_primary rounded-full  border border-transparent text-base text-white block py-2  text-center cursor-pointer flex-1 whitespace-nowrap px-4"
          (click)="showPickDropModel(car)">Schedule
          pick/drop-off</a>
      </div>
      <div *ngIf="(isDealer && currentRoute == 'car-rejected') || (!isDealer && car.rejected ==1)">
      <div class="action-button space-y-4 mt-6">
        <div class="btn-box flex flex-wrap gap-4">
          <a routerLink="/seller/view-car-info/{{car?.id}}"
          class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center" *ngIf="!isDealer">View
          Vehicle Info</a>
          <a routerLink="/dealer/view-car-info/{{car?.id}}"
          class="bg-green rounded-full border border-transparent text-base text-white block py-2 flex-1 whitespace-nowrap px-4 text-center" *ngIf="isDealer">View
          Vehicle Info</a>
          <a class="bg_primary rounded-full border border-transparent text-base text-white block py-2 lg:w-6/12 lg:flex-none lg:whitespace-normal flex-1 whitespace-nowrap  text-center cursor-pointer px-4 ml-auto"
            (click)="showRejected(car)" >
            Rejection Information </a>
        </div>
      </div>
  </div>
      <div class="model-action text-base sm:text-lg font-medium mt-4 text-center flex flex-wrap gap-4"
        *ngIf="isDealer && schedulePage">
        <a routerLink="/dealer/car-history/{{car?.id}}"
          class="bg-orange rounded-full py-2 text-white block flex-1 whitespace-nowrap px-4">History</a>
        <a routerLink="/dealer/view-car-info/{{car?.id}}"
          class="bg-green rounded-full py-2 text-white block flex-1 whitespace-nowrap px-4">View
          Vehicle Info</a>
        <a class="bg-green rounded-full py-2 text-white block cursor-pointer flex-1 whitespace-nowrap px-4"
          (click)="showCustomer(car?.userId,0)">View Customer Info</a>
        <a class="bg_primary rounded-full py-2 text-white block cursor-pointer flex-1 whitespace-nowrap px-4"
          *ngIf="currentRoute == 'schedule-car-pickup'  || currentRoute == 'schedule-car-dropoff'"
          (click)="showSummary(car)">Vehicle {{currentRoute == 'schedule-car-pickup' ? 'Pick-Up' : 'Drop-Up'}} Info</a>
          <a class="bg_primary rounded-full py-2 text-white block cursor-pointer flex-1 whitespace-nowrap px-4"
          (click)="showPickDropModel(car)" *ngIf="!car.rejectionStatus">Update Vehicle {{car.pickup==1 ? 'Pick-Up' : 'Drop-Up'}} Info</a>
        <a class="bg_primary rounded-full py-2 text-white block cursor-pointer flex-1 whitespace-nowrap px-4"
          *ngIf="currentRoute == 'car-overview' && !car.rejectionStatus" (click)="updateStatus(car)">Update Vehicle Status</a>
          <a class="bg_primary rounded-full border border-transparent text-base text-white block py-2 lg:w-6/12 lg:flex-none lg:whitespace-normal flex-1 whitespace-nowrap  text-center cursor-pointer px-4"
          (click)="showRejected(car)" *ngIf="car.rejectionStatus">
          Rejection Information </a>
      </div>
    </div>
  </div>

  <app-bid></app-bid>
  <app-popup></app-popup>
  <app-user-info></app-user-info>
  <app-update-car-status></app-update-car-status>
  <app-schedule-pick-drop></app-schedule-pick-drop>